import logo from './uscg-shenyang 5.svg'
import logo2 from './uscg-shenyang 5.png'
import date from './Jan 26th 2024.svg';
import dateMobile from './Jan 26th 2024 (1).svg';
import footerImg1 from './Group 2792.svg';
import footerImg2 from './Group 2785.svg';
import './App.css';

function App() {

  const isMobile = window.innerWidth <= 991;

  return (
    <div className="App">

      <div className='top-panel'>
        <div className='header'>
          <img className='logo' src={isMobile ? logo2 : logo} />
          <div>
            <div className='header-text'>
              RELO English Teacher <br/>
              Training Seminar
            </div>
            <div className='special'>The World is Your Classroom</div>
          </div>
          <img className='date' src={date} />
        </div>
        <div className='mobile-header'>
          <img className='logo' src={logo} />
          <div>
            <div className='header-text'>
              RELO English Teacher <br/>
              Training Seminar
            </div>
            <div className='special'>The World is Your Classroom</div>
            <img className='mobile-date' src={dateMobile} />
          </div>
        </div>
        {/*<iframe id="myframe" width="100%" height={isMobile? 600 : 1000} frameBorder="0" allowFullScreen*/}
        {/*        src="https://mudu.tv/live/watch/ob8391jo"></iframe>*/}
        <video
          preload="none"
          width={isMobile? '70%' : '95%'}
          src={'https://file.udty.tech/20240127/1%E6%9C%8827%E6%97%A5%20%282%29.mp4'}
          style={{outline: "none", objectFit: 'cover'}}
          controls
          disablePictureInPicture
          controlsList="nodownload"
        ></video>
      </div>
      <div className='footer'>
        <img src={footerImg1} />
        <img src={footerImg2} />
      </div>
    </div>
  );
}

export default App;
